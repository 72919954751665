import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Fragment } from 'react';

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ');
}

type Props = {
	label: string;
	options: string[];
	selectedOption: string;
	onChange: (...event: any[]) => void;
};
const SelectList = ({ label, onChange, options, selectedOption }: Props) => {
	if (options.length === 0) return null;
	if (!selectedOption) throw new Error('selectedOption is required.');

	return (
		<Listbox value={selectedOption} onChange={onChange}>
			{({ open }) => (
				<>
					<Listbox.Label className="block text-sm font-semibold text-gray-700">
						{label}
					</Listbox.Label>
					<div className="relative mt-1">
						<Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-gray-900 focus:outline-none focus:ring-1 focus:ring-gray-500 sm:text-sm">
							<span className="inline-flex w-full truncate">
								<span className="truncate">{selectedOption}</span>
							</span>
							<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
								<ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
							</span>
						</Listbox.Button>

						<Transition
							show={open}
							as={Fragment}
							leave="transition ease-in duration-100"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
								{options.map((option, index) => (
									<Listbox.Option
										key={option}
										className={({ active }) =>
											classNames(
												active ? 'bg-gray-100' : 'text-gray-900',
												'relative cursor-default select-none py-2 pl-8 pr-4',
											)
										}
										value={option}
									>
										{({ selected, active }) => (
											<>
												<div className="flex">
													<span className={classNames(selected ? 'font-semibold' : 'font-normal')}>
														{option}
													</span>
												</div>

												{selected ? (
													// Select Checkmark
													<span
														className={classNames(
															active ? 'text-black' : 'text-black',
															'absolute inset-y-0 left-0 flex items-center pl-1.5',
														)}
													>
														<CheckIcon className="h-5 w-5" aria-hidden="true" />
													</span>
												) : null}
											</>
										)}
									</Listbox.Option>
								))}
							</Listbox.Options>
						</Transition>
					</div>
				</>
			)}
		</Listbox>
	);
};

export default SelectList;
