import { type ZodModifier, type ZodModifierGroup, formatCurrency } from '@packages/core';
import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';

type Props = {
  modifierGroups: ZodModifierGroup[];
  modifiers: ZodModifier[];
  // React hook form props
  onChange: (...event: any[]) => void;
  selectedModifiers: ZodModifier[];
};

const ModifierMultiSelectList = ({
  modifierGroups,
  modifiers,
  selectedModifiers,
  onChange,
}: Props) => {
  // Because <input ..> `value` prop needs to be a string, we store a list of ids, then map these back to
  // the Modifier objects (to keep consistent with 'one' modifiers).
  const [selectedModifierUuids, setSelectedModifierUuids] = useState<string[]>(
    selectedModifiers.map((m) => m.uuid),
  );

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedModifierUuids) {
      if (e.target.checked) {
        setSelectedModifierUuids([
          ...selectedModifierUuids.filter((id) => id !== e.target.value),
          e.target.value,
        ]);
      } else {
        setSelectedModifierUuids([...selectedModifierUuids.filter((id) => id !== e.target.value)]);
      }
    }
  };

  useEffect(() => {
    onChange(modifiers.filter((m) => selectedModifierUuids.includes(m.uuid)));
  }, [selectedModifierUuids]);

  if (modifierGroups.length === 0 || modifiers.length === 0) return null;
  return (
    <fieldset>
      <legend className="text-lg font-medium text-gray-900">Extras</legend>
      <div className="mt-2 divide-y divide-gray-200 border-t border-b border-gray-200 bg-white">
        {modifiers.map((modifier) => (
          <div key={modifier.uuid} className="relative flex items-start py-3 pl-2">
            {/* Checkbox */}
            <div className="flex h-5 items-center">
              <input
                id={`modifier-${modifier.uuid}`}
                name={`modifier-${modifier.uuid}`}
                type="checkbox"
                value={modifier.uuid}
                className="h-4 w-4 cursor-pointer rounded border-gray-300 text-gray-900 focus:ring-gray-600"
                onChange={handleOnChange}
                checked={selectedModifiers.map((m) => m.uuid).includes(modifier.uuid)}
              />
            </div>

            {/* Label */}
            <div className="ml-2 min-w-0 flex-1 text-sm">
              <label
                htmlFor={`modifier-${modifier.uuid}`}
                className="cursor-pointer select-none text-gray-700"
              >
                <span>{modifier.name}</span>
                {modifier?.price !== 0 && (
                  <span className="ml-2 text-gray-400">{`+${formatCurrency(modifier.price)}`}</span>
                )}
              </label>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
  );
};

export default ModifierMultiSelectList;
