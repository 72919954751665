import SelectList from '@/components/SelectList';
import TextArea from '@/components/TextArea';
import { addOrUpdateOrderItem } from '@/orderStore';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  type Modifier,
  type ModifierGroup,
  type OrderItem,
  type OrderItemFormData,
  applyOrderItemFormData,
  formatCurrency,
  getOrderItemDefaultValues,
  orderItemFormSchema,
} from '@packages/core';
import { forwardRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ModifierMultiSelectList from './ModifierMultiSelectList';
import ModifierSelectList from './ModifierSelectList';

type Props = {
  modifierGroups: ModifierGroup[];
  modifiers: Modifier[];
  orderItem: OrderItem;
  afterValidSubmit?: () => void;
};

const OrderItemForm = forwardRef<HTMLFormElement, Props>(
  ({ modifierGroups, modifiers, orderItem, afterValidSubmit }, ref) => {
    const sizeModifierGroup = modifierGroups.find(
      (mg) => mg.uuid === orderItem?.menuItemSizeModifierGroupUuid,
    );
    const oneModifierGroups = modifierGroups.filter(
      (mg) => mg.qtyMax === 1 && mg.uuid !== orderItem?.menuItemSizeModifierGroupUuid,
    );
    const manyModifierGroups = modifierGroups.filter((mg) => mg.qtyMax > 1);

    const form = useForm<OrderItemFormData>({
      resolver: zodResolver(orderItemFormSchema), // Useful to check TypeScript regressions
      defaultValues: getOrderItemDefaultValues(orderItem, modifierGroups, modifiers),
    });

    const onValidSubmit = async (formData: OrderItemFormData) => {
      try {
        orderItem = applyOrderItemFormData(orderItem, modifierGroups, formData);
        addOrUpdateOrderItem(orderItem);
        if (afterValidSubmit) afterValidSubmit();
      } catch (e) {
        console.error(e);
        throw new Error(JSON.stringify(e));
      }
    };

    return (
      <form
        className="grid items-start gap-6 pt-3 sm:grid-cols-2"
        onSubmit={form.handleSubmit(onValidSubmit, (e) => console.error(e))}
        ref={ref}
      >
        {/* column 1: One mods */}
        <div>
          {/* Price only (if item has no size mods) */}
          {!sizeModifierGroup && !!orderItem.menuItemPrice && (
            <div className={'mb-3'}>{formatCurrency(orderItem.menuItemPrice)}</div>
          )}

          {/* Qty */}
          <div className="mb-3 w-20">
            <Controller
              control={form.control}
              render={({ field: { onChange, value } }) => (
                <SelectList
                  options={Array(99)
                    .fill(1)
                    .map((n, i) => (n + i).toString())}
                  selectedOption={value.toString()}
                  onChange={(value) => {
                    onChange(+value); // convert from string to number
                  }}
                  label={'Qty'}
                />
              )}
              name={'qty'}
            />
          </div>

          {/* Size Modifiers */}
          {sizeModifierGroup && (
            <div className="mb-3">
              <Controller
                control={form.control}
                render={({ field: { onChange, value } }) => (
                  <ModifierSelectList
                    modifierGroup={sizeModifierGroup}
                    modifiers={modifiers.filter(
                      (m) => m.modifierGroupUuid === sizeModifierGroup.uuid,
                    )}
                    onChange={onChange}
                    selectedModifier={value}
                  />
                )}
                name={'selectedSizeModifier'}
              />
            </div>
          )}

          {oneModifierGroups.map((mg, index) => {
            return (
              <div key={mg.uuid} className="mb-3">
                <Controller
                  control={form.control}
                  render={({ field: { onChange, value } }) => (
                    <ModifierSelectList
                      modifierGroup={mg}
                      modifiers={modifiers.filter((m) => m.modifierGroupUuid === mg.uuid)}
                      onChange={onChange}
                      selectedModifier={value}
                    />
                  )}
                  name={`selectedOneModifiers.${index}`}
                />
              </div>
            );
          })}

          {/* Item notes */}
          <div className="mt-3 block text-sm font-semibold text-gray-700">Notes</div>
          <Controller
            control={form.control}
            render={({ field: { onBlur, onChange, value, name } }) => (
              <TextArea
                placeholder="Add any notes here..."
                className={'mt-1'}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                id={`txt-${name}`}
                rows="2"
              />
            )}
            name={'notes'}
          />
        </div>

        {/* column 2: Extra mods */}
        {manyModifierGroups && manyModifierGroups.length > 0 && (
          <div>
            <Controller
              control={form.control}
              render={({ field: { onChange, value } }) => (
                <ModifierMultiSelectList
                  selectedModifiers={value}
                  onChange={onChange}
                  modifierGroups={manyModifierGroups}
                  modifiers={modifiers.filter((m) =>
                    manyModifierGroups.map((mg) => mg.uuid).includes(m.modifierGroupUuid),
                  )}
                />
              )}
              name={'selectedManyModifiers'}
            />
          </div>
        )}
      </form>
    );
  },
);

export default OrderItemForm;
